import { useState } from "react";


function App() {
  const [isSendFile, setIsSendFile] = useState(false)
  const uploadEvidence = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    setIsSendFile(true)
  }
  
  const [isSendLead, setIsSendLead] = useState(false)
  const uploadLead = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const response = await fetch('/form.php', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
    setIsSendLead(true)
  }

  return (
    <div className="flex flex-col [&>div]:py-20">

      {/* SECTION 1 */}
      <div className="bg-gray-900 flex justify-center">
          <div className="w-full max-w-[1200px] space-y-10">
            <div className="flex flex-col text-white text-center font-semibold">
              <h1 className="text-[35px]">Enough is Enough: Stop Amit Shah's Corruption</h1>
              <h2 className="text-gray-300 text-[22px]">Uncovering the legacy of abuse and corruption under India's Union Home Minister, Amit Shah.</h2>
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <img src="/images/photo1.jpeg" className="w-[300px] h-[300px] object-cover rounded-full" />
              <div className="flex flex-col">
                <span className="text-white font-semibold text-[20px]">Record of Controversy and Allegation</span>
                <p className="text-gray-400 text-2xl max-w-[700px] text-xl italic font-semibold">
                  Amit Shah, India's Union Home Minister, stands accused of numerous acts of corruption and human rights abuses. From his involvement in the Gujarat riots to the contentious policies in Kashmir, Shah's tenure has been marked by serious allegations, including extortion and undue influence over government operations.
                </p>
              </div>
            </div>
          </div>        
      </div>



      {/* SECTION 3 */}
      <div className="bg-gray-300 flex justify-center py-10">
          <div className="w-full max-w-[1200px] flex flex-col space-y-8 items-center">
            <h2 className="text-[30px] text-gray-900 font-bold">Watch: The Impact of Amit Shah's Policies on India's Fabric</h2>
            <video width="700" height="340" controls>
              <source src="/images/vid2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          </div>
      </div>


      {/* SECTION 2 */}
      <div className="bg-blue-200 flex justify-center py-10">
          <div className="w-full max-w-[900px] space-y-8">

            <Avatar img="/images/Sanjay.png" title='Sanjay Mishra - "The Gatekeeper"' text="Former chief of the Enforcement Directorate (ED), instrumental in manipulating legal and financial environments to benefit Shah's political maneuvers." ltr={true} />
            <Avatar img="/images/Karnal2.jpg" title='Karnal Singh - "The Enforcer"' text="As the ex-chief of the ED, played a pivotal role in targeting political adversaries under the guise of legal scrutiny, ensuring Shah's dominance in political circles." ltr={false} />
            <Avatar img="/images/Adani.png" title='Adani - "The Navigator"' text="The business magnate uses his influence to manipulate economic policies in favor of Shah, securing financial resources necessary for sustaining political power." ltr={true} />
            <Avatar img="/images/Tushar.png" title='Tushar Mehta - "The Shield"' text="India's Solicitor General, adept at using legal frameworks to protect Shah from judicial consequences, ensuring legal threats are neutralized." ltr={false} />
            
          </div>
      </div>

      {/* SECTION 4 */}
      <div className="bg-gray-900 flex justify-center py-10">
          <div className="w-full max-w-[1200px] flex flex-col items-center space-y-10 justify-center">

            <h2 className="text-gray-100 text-[30px] font-bold">Send Evidence Anonymously</h2>
            <div className="flex flex-row items-center space-x-6 text-white text-[18px] font-semibold">
              {
                isSendFile ? (
                  <div className="card p-10">
                    <h2 className="text-2xl text-gray-900 font-bold">Thanks for the information!</h2>
                  </div>
                ) : (
                  <form className="card w-full max-w-[450px] space-y-2.5" onSubmit={uploadEvidence}>
                    {/* <input className="inp" /> */}
                    <input name="file" type="file" className="inp text-gray-600 font-normal" required />
                    <textarea name="text" className="inp text-gray-800" rows={4} placeholder="Description of the information">
                    </textarea>
                    <div className="flex justify-end items-end">
                      <button type="submit" className="rounded-sm text-white font-semibold text-xl py-2 px-4 bg-gray-800 hover:bg-gray-900">SEND</button>
                    </div>
                  </form>
                )
              }

              <div className="max-w-[380px] text-xl">
                If you have evidence or firsthand accounts of corruption related to Amit Shah, please share it here. Your identity will remain anonymous.
              </div>
            </div>

          </div>
      </div>


      {/* SECTION 5 */}
      <div className="bg-gray-300 flex justify-center py-10">
          <div className="w-full max-w-[1200px] flex flex-col space-y-10 text-center">
            <h2 className="text-lg font-bold text-[27px] text-center text-gray-900">
              Amit Shah's policies have not only reshaped Indian politics but have also left a trail of human suffering. From the streets of Gujarat to the valleys of Kashmir, countless individuals have felt the harsh impact of his actions. It’s time to demand accountability and justice.
            </h2>

            <div className="flex flex-col items-center justify-center space-y-12">

              <div className="flex flex-col max-w-[600px]">
                <div className="text-lg font-semibold text-gray-700">
                  <p className="italic ">
                  "The actions of Amit Shah have continuously undermined the democratic foundations of our nation. It's imperative that he be held accountable."
                  </p>
                  <span className="font-bold text-gray-800">An unnamed Member of Parliament.</span>
                </div>
              </div>

              <div className="flex flex-col max-w-[600px]">
                <div className="text-lg font-semibold text-gray-700">
                  <p className="italic ">
                  "Amit Shah's tenure as Home Minister has seen a disturbing disregard for human rights and the rule of law in India."
                  </p>
                  <span className="font-bold text-gray-800">Amnesty International.</span>
                </div>
              </div>
            </div>
          </div>
      </div>


      {/* SECTION 6 */}
      <div className="bg-blue-100 flex justify-center py-10">
          <div className="w-full max-w-[1200px] text-[30px] font-bold text-center space-y-8 text-gray-900 flex flex-col items-center">
            <div className="flex flex-row [&>img]:w-[200px] [&>img]:rounded-full space-x-[50px]">
              <img src="/images/photo2.jpeg" />
              <img src="/images/photo3.jpeg" />
            </div>
            <h2>Demand a Full Investigation into Amit Shah's Conduct</h2>
            <h2>Urge your representatives to hold Amit Shah accountable.</h2>
            <h2>Join us at local and national rallies demanding justice and transparency.</h2>

            {
              isSendLead ? (
                <div className="card p-10">
                  <h2 className="text-2xl">Thank you so much for signing up!</h2>
                </div>
              ) : (
              <form className="card border shadow-2xl w-full max-w-[450px] space-y-2.5 text-[16px] !mt-12" onSubmit={uploadLead} autoComplete="off">
                <input className="inp" placeholder="First name" name="first_name" required />
                <input className="inp" placeholder="Last name" name="last_name" required />
                <input className="inp" placeholder="email" name="email" type="email" required />
                <input className="inp" placeholder="phone" name="phone" required />
                <div className="flex justify-end items-end">
                  <button type="submit" className="rounded-sm text-white font-semibold text-xl py-2 px-4 bg-gray-800 hover:bg-gray-900">SIGN UP</button>
                </div>
              </form>
              )
            }

          </div>
      </div>




    </div>
  );
}

export default App;


const Avatar = ({img, title, text, ltr}) => {
  return (
    <div className={`flex ${ltr ? 'flex-row' : 'flex-row-reverse'} items-center space-x-6 max-w-[850px]`}>
      <img src={img} className="w-[200px] h-[200px] object-cover rounded-full" />
      <div className="flex flex-col font-semibold">
        <h2 className="font-bold text-2xl">{title}</h2>
        <p className="text-lg">{text}</p>
      </div>
    </div>

  )
}




{/* MENU */}
{/* <div className="bg-black text-white flex items-center justify-center p-4">
  <div className="w-full max-w-[1200px] flex flex-row items-center justify-between">
    <span>THE START</span>
    <div className="flex flex-row items-center space-x-4">
      <span>ABOUT</span>
      <span>MORE DATA</span>
    </div>
  </div>
</div> */}


{/* SECTION 2 */}
{/* <div className="bg-green-700 flex justify-center py-10">
    <div className="w-full max-w-[1200px]">
      BBB
    </div>
</div> */}